import React from 'react';
import BannerImage from "../../assets/vaccdetails_bkgnd.png";
import WriteToUs from "../../components/WriteToUs";

function Pcv() {
  return (
    <div style={{fontFamily: "Roboto", backgroundImage: `url(${BannerImage})` }}>      
        <div style={{fontSize:"16sp", textAlign:'left'}}>
          <h1>Pneumococcal Vaccine (PCV)</h1>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>About Vaccine:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
          <p>Pneumococcal Vaccine protects children from pneumococcal disease,
             a serious infection caused by a bacterium known as Streptococcus 
             pneumoniae. This vaccine is important because infants and very
             young children are at higher risk for several dangerous infections, 
             including pneumonia and bacterial meningitis. 
          </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Total number of dosages: 3</h1>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Dosage Time:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'left'}}>
          <p>6 Weeks
             <br></br>
             10 Weeks
             <br></br>
             15 Months
        </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Importance:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
          <p>Pneumococcal disease is an infection caused by the bacteria
             Streptococcus pneumoniae or pneumococcus. People can be infected
             with the bacteria, or they can carry it in their throat, and not
             be ill. Those carriers can still spread it, primarily in droplets 
             from their nose or mouth when they breathe, cough, or sneeze.
            <br></br>
            <br></br>
             Depending on what organ or part of the body is infected, pneumococcal
             disease will cause any of several serious illnesses, including Bacterial 
             meningitis, Pneumonia, Otitis media, Bacteremia and Sinus infections.
            <br></br>
            <br></br>
             Pneumococcal Vaccine protects children from the pneumococcal disease.
            </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>After Effects:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
          <p>
            Mild side effects include Redness, tenderness, or swelling where 
            the shot is given, fever higher than 100.4 F in about one out of every
            three infants, fever higher than 102.2 F in about one out of every 
            50 children and occasional incidence of fussiness, drowsiness, or 
            loss of appetite.
          </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Tentative Cost:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'left'}}>
          <p>
          Rs 3800 - 4800
          </p>
        </div>  

        <br></br>
        <br></br>
        <h1 style={{marginLeft: "18sp"}} >Write to us:</h1>

        <WriteToUs/>
    
    </div>  
    )
}

export default Pcv