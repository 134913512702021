import BabyFoodMashed from "../assets/nutrition_baby_food_purees1.jpg";
import NutritionFruits from "../assets/nutrition_fruits1.png";
import NutritionDairy from "../assets/nutrition_dairy_products1.png";
import Vegan from "../assets/vegan.jpg";
import Pineapple from "../assets/pineapple.jpg";
import Expensive from "../assets/expensive.jpg";

export const NutritionList = [
  {
    name: "Upto 12 Months",
    image: BabyFoodMashed,
    hyplink: "/faq",
  },
  {
    name: "From 1 to 18 Years",
    image: NutritionFruits,
    hyplink: "/faq",
  },
  {
    name: "Essential Nutrients",
    image: NutritionDairy,
    hyplink: "/faq",
  },
];
