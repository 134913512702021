import React from 'react'
import BannerImage from "../../assets/vaccdetails_bkgnd.png";
import WriteToUs from "../../components/WriteToUs";

function Rotavirus() {
  return (
    <div style={{fontFamily: "Roboto", backgroundImage: `url(${BannerImage})` }}>      
        <div style={{fontSize:"16sp", textAlign:'left'}}>
          <h1>Rotavirus</h1>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>About Vaccine:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
          <p>Rotavirus vaccine belongs to the category of medicine called
            immunizing agent used to prevent rotavirus infection.  It is
            given orally and used for the prevention of diarrhea due to
            rotavirus.  The vaccine is used to help avoid this disease in
            children. Rotavirus disease is most frequent in infants and young
            children. 
          </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Total number of dosages: 3</h1>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Dosage Time:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'left'}}>
          <p>6 Weeks
             <br></br>
             10 Weeks
             <br></br>
             14 Weeks
        </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Importance:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
          <p>Rotavirus disease is highly contagious. Rotavirus gets its name
             from the fact that, under a microscope, the virus resembles a wheel
             .The germ is present in the stool of an infected person and can remain
             viable for a long time on contaminated surfaces, including people's 
             hands. Children catch it by touching something that's contaminated
             and then putting their hands in their mouth.
            <br></br>
            <br></br>
            Symptoms of rotavirus infection, which may last up to eight days
            , include fever, nausea, vomiting, abdominal cramps, and frequent,
            watery diarrhea.
            <br></br>
            <br></br>
            Rotavirus vaccine provides long-lasting protection against infection from 
            the rotavirus. It works by irritating the immune system to generate 
            antibodies (proteins) without affecting a disease. In the occurrence
            of infection, these antibodies protect against diarrhea produced by
            rotavirus.
            </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>After Effects:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
          <p>Most children who get the vaccine have no problem at all. However
             , there is a slight chance your child may have temporary, mild effects
             following the vaccine including Increased irritability, diarrhea 
             and vomiting
          </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Tentative Cost:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'left'}}>
          <p>
          Rs 900 - 1099
          </p>
        </div>  

        <br></br>
        <br></br>
        <h1 style={{marginLeft: "18sp"}} >Write to us:</h1>

        <WriteToUs/>
    
    </div>  
  )
}

export default Rotavirus