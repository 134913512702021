import React from "react"
import AboutImage from "../assets/about_image.png";
import InnovationImage from "../assets/innovation_image3.png";
import AboutBottomBkgnd from "../assets/about_bottom_bkgnd.png";
import "../styles/About.css";

function About() {
    return (
        <div className="about" style={{backgroundImage: `url(${AboutImage})` }}>
            <div className="aboutItem">
                <div className="leftSide">
                    <img src={InnovationImage} />
                </div>

                <div className="rightSide">
                    <h1> About Us</h1>
                    <p>
                    <a href="https://www.seapebble.co/" target="_blank"> SeaPebble Technologies Private Ltd.</a> is a Software Products Company 
                    involved in the development of innovative products in the Health Care domain.
                    </p>
                    <br></br>
                    <p>
                    ShishuRaksha is a Vaccination and Child Care App that provides useful features
                    on Vaccinating your child, tips to take care of your child and nutrition tips
                    for your child.
                    </p>
                </div>
            </div>
            
        </div>
    )
}

export default About
