import React from 'react'
import Divider from "@material-ui/core/Divider";


function KidsCareTipsItem({image, heading, desc, hyplink}) {
    return (
        <div className="kidscaretipsItem" >
            <br></br>
            <h1>{heading}</h1>
            <div className="leftSide" >
                <img src={image}/>
            </div>
            <div className="rightSide">
                <p>{desc}</p> 
                <a href={hyplink}> KNOW MORE.. </a>
            </div>               
        </div>
    )
}

export default KidsCareTipsItem
