import React from 'react'
import "../styles/WriteToUs.css";
import emailjs from "emailjs-com";


function WriteToUs() {

    function sendemail(e) {

        e.preventDefault();

        emailjs.sendForm('service_i6hgr9d', 'template_c8jiwdg', e.target, 'x15o18WPfGTxR9x6G')
          .then((result) => {
              //console.log(result.text);
              alert("message sent successfully!");
          }, (error) => {
              //console.log(error.text);
              alert(error.text);
          });
          e.target.reset();
    }

    return (
        <form className="writetous" id="contact-form" onSubmit={sendemail}> 
            <div className='name'>
                <input name="name" placeholder="Name" type="text"/>
            </div>
            <div className='email'>
                <input name="email" placeholder="Email Address" type="email"/>
            </div>
            <div className='subject'>
                <input name="subject" placeholder="Subject" type="subject"/>
            </div>
            <div className='message'>
                <textarea
                    rows="10"
                    placeHolder="Your Message"
                    name="message"
                    required
                >
                </textarea>
            </div>    
            <div className='submit'>
                <input type="submit" value="Send Message"/>
            </div>
        </form>
    )
}

export default WriteToUs