import React from 'react';
import { VaccinesList } from "../helpers/VaccinesList.js";
import VaccinesItem from "../components/VaccinesItem";
import "../styles/Vaccines.css";
import VaccinationBkgnd from "../assets/vaccines_bkgnd.png";



function Vaccines() {
    return <div className="vaccines" id="vaccines" style={{backgroundImage: `url(${VaccinationBkgnd})` }}>
                <h1 className="vaccinesTitle">Vaccines</h1>
                <div className="vaccinesList">
                    {VaccinesList.map((vaccinesItem, key) => {
                        return <div>
                            <VaccinesItem 
                            key={key}
                                heading={vaccinesItem.heading}
                                hyplink={vaccinesItem.hyplink}                       
                            />
                        </div>                
                    })} 
                </div>

                <a href="/faq"> KNOW MORE ABOUT OTHER VACCINES.. </a>  
          </div>
}

export default Vaccines;
