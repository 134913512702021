import React from 'react'
import BannerImage from "../../assets/vaccdetails_bkgnd.png";
import WriteToUs from "../../components/WriteToUs";



function Tdap() {
  return (
    <div style={{fontFamily: "Roboto", backgroundImage: `url(${BannerImage})` }}>
        <div style={{fontSize:"16sp", textAlign:'left'}}>
          <h1>Tetanus and Diptheria Toxoids and a Cellular Pertussis
            Vaccine (TdaP)
          </h1>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>About Vaccine:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
          <p>The TdaP vaccine is a combination vaccine that works against the
            three diseases and is made up of diphtheria and tetanus toxoids.
            It also kills whole cells of the organisms that cause pertussis.
            It should be noted that most children who get all their shots will
            are protected against these diseases during their childhood. The 
            risk of getting these diseases will rise up again if children are
            not immunised.
          </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Total number of dosages: 1</h1>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Dosage Time:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'left'}}>
          <p>
            10 to 12 Years
          </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Importance:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
          <p>Diphtheria, Tetanus, and Pertussis are three very serious diseases
            that are tackled by the DTP vaccine. Let’s look at these three medical
            conditions in a little more detail:
            <br></br>
            <br></br>
            Diphtheria causes an infection and a thick covering in the nose
            and throat. This can cause breathing problems, paralysis (unable to
            move), heart failure, and even death.
            <br></br>
            <br></br>
            Tetanus (Lockjaw) happens when a wound like cut gets infected with
            tetanus bacteria (germ) often found in dirt. The bacteria in the wound
            creates a poison that causes muscles all over the body to spasm
            (tighten) painfully. This can cause the jaw to "lock" so your child
            cannot open his mouth or swallow. Tetanus can also lead to death.
            <br></br>
            <br></br>
            Pertussis (Whooping Cough) causes very bad coughing spells which make
            it hard for your child to eat, drink, or breathe. These coughing 
            spells can last for weeks and can lead to pneumonia (lung infection),
            seizures (convulsions), brain damage, and death.
            </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>After Effects:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
          <p>Give your child an aspirin-free pain reliever. This is especially
            important if your child has ever had a seizure. It is also very
            important if a parent, brother, or sister has ever had a seizure.
            Ask your child's caregiver how much pain reliever to give your
            child and how often to give it. Call your child's caregiver if your
            child is crying non-stop. 
          </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Tentative Cost:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'left'}}>
          <p>
          Rs 1098
          </p>
        </div>

        <br></br>
        <br></br>
        <h1 style={{marginLeft: "18sp"}} >Write to us:</h1>

        <WriteToUs/>

    </div>
  )
}

export default Tdap