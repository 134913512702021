import React from 'react'
import BannerImage from "../../assets/vaccdetails_bkgnd.png";
import WriteToUs from "../../components/WriteToUs";


function Bcg() {
  return (
    <div style={{fontFamily: "Roboto", backgroundImage: `url(${BannerImage})` }}>
        <div style={{fontSize:"16sp", textAlign:'center'}}>
        <h1>Vaccines Details</h1>
        </div>
                
        <div style={{fontSize:"16sp", textAlign:'left'}}>
        <h1>Bacille Calmette-Guerin (BCG)</h1>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
        <h1>About Vaccine:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
        <p>Bacille Calmette-Guerin (BCG) vaccine is
            primarily used against Tuberculosis (TB). ­The BCG
            vaccine is the only one of its kind to offer protection
            against TB, and is currently a part of the immunization
            schedule under the Universal Immunization Programme.</p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
        <h1>Total number of dosages: 1</h1>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
        <h1>Dosage Time:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'left'}}>
        <p>At birth</p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
        <h1>Importance:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
        <p>Tuberculosis is a highly infectious disease. It can
            cause tiredness, coughing, fever, and shortness of breath.
            TB usually affects the lungs, but it can also affect other 
            parts of the body, such as the lymph nodes, bones, joints
            and kidneys. A very serious case of the TB disease can also
            cause meningitis. TB spreads from person to person 
            through coughing, spitting or sneezing into the air.
            <br></br>
            <br></br>
            Tuberculosis vaccination for children, is an important decision
            that needs to be made, as children have been found to be 
            particularly vulnerable to the disease.
            <br></br>
            <br></br>
            In countries like India, which has an expansive case history
            of TB, medical practitioners recommend BCG vaccines for
            children, which is to be administered at the time of birth.
            There are also cases where TB vaccines for children are
            administered when the infant is between the age of one, up
            to the age of 5 years.
            </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
        <h1>After Effects:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
        <p>Reactions to the BCG vaccine are common, but serious 
            long-term complications are rare. Most children develop a
            sore at the injection site. Once healed, the sore may 
            leave a small scar. In 1 to 6 weeks, a small red blister 
            may appear where the injection was given. After 6 to 12 
            weeks, the blister may turn into a small, weeping sore. 
            If this happens, cover the site with gauze to allow air 
            to get in. Do not use sticking plasters. The sore may 
            take up to three months to heal, and may leave a small 
            scar. This is normal.
            </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
        <h1>Tentative Cost:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'left'}}>
        <p>
            Rs 10 - 25
        </p>
        </div>

        <br></br>
        <br></br>
        <h1 style={{marginLeft: "18sp"}} >Write to us:</h1>

        <WriteToUs/>
    </div>

  )
}

export default Bcg