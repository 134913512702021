import React from "react";
import { KidsCareTipsList } from "../helpers/KidsCareTipsList.js";
import KidsCareTipsItem from "../components/KidsCareTipsItem";
import "../styles/KidsCareTips.css";
import KidsCareTipsBkgnd from "../assets/kids_care_tips_bkgnd.png";



function KidsCareTips() {
  return <div className="kidscaretips" id="kidscaretips" style={{backgroundImage: `url(${KidsCareTipsBkgnd})` }}>
            <h1 className="kidscaretipsTitle">Kids Caring Tips</h1>
            <div className="itemList">
                {KidsCareTipsList.map((kidscaretipsItem, key) => {
                    return <div>
                      <KidsCareTipsItem 
                      key={key}
                          heading={kidscaretipsItem.heading}
                          image={kidscaretipsItem.image}
                          desc={kidscaretipsItem.desc}
                          hyplink={kidscaretipsItem.hyplink}                       
                      />
                    </div>                
                })} 
            </div>
          </div>
}

export default KidsCareTips
