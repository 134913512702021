import React from 'react';
import BannerImage from "../../assets/vaccdetails_bkgnd.png";
import WriteToUs from "../../components/WriteToUs";

function Tcv() {
  return (
    <div style={{fontFamily: "Roboto", backgroundImage: `url(${BannerImage})` }}>      
        <div style={{fontSize:"16sp", textAlign:'left'}}>
          <h1>Typhoid Conjugate Vaccine (TCV)</h1>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>About Vaccine:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
          <p>
          Typhoid Conjugate Vaccine is an active immunizing agent. It is
          given to prevent typhoid fever caused by certain bacteria called
          S. Typhi. Typhoid fever is an infection that spreads through intake 
          of contaminated food and water. The vaccine helps develop immunity by
          initiating a mild infection. This type of infection does not cause
          illness but stimulates the body's immune system to produce antibodies
          (proteins) to protect against any future infections. 
          </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Total number of dosages: 1</h1>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Dosage Time:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'left'}}>
          <p>
             9-12 Months
          </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Importance:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
            <p>
            Typhoid fever is a bacterial infection that can spread throughout 
            the body, affecting many organs. Without prompt treatment, it can 
            cause serious complications and can be fatal. It's caused by a bacterium
            called Salmonella typhi. 
            <br></br>
            <br></br>
            Typhoid fever is an infection that spreads through intake of contaminated 
            food and water. The TCV vaccine helps develop immunity against the Typhoid
            disease.
            </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>After Effects:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
            <p>
            Some common side effects of this medicine include mild reactions at the
            injection site (pain, redness at the injection site), fever and headache.
            </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Tentative Cost:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'left'}}>
          <p>
          Rs 280
          </p>
        </div>  

        <br></br>
        <br></br>
        <h1 style={{marginLeft: "18sp"}} >Write to us:</h1>

        <WriteToUs/>
    
    </div>  
    )
}

export default Tcv