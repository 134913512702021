import KidsCareTips from "./KidsCareTips";
import Vaccines from "./Vaccines";
import Nutrition from "./Nutrition";
import Vaccination from "./Vaccination";
import "../styles/ProductFeatures.css";
import ProductFeaturesBkgnd from "../assets/product_features_bkgnd.png";
import VaccinationSlideShow from "../components/VaccinationSlideShow";


function ProductFeatures() {
  return (
    <div className="productfeatures" style={{backgroundImage: `url(${ProductFeaturesBkgnd})` }} > 
      <h1 className="productfeaturesTitle">Product Features</h1>
          <VaccinationSlideShow/>
          <Vaccination/>
          <Vaccines/>
          <KidsCareTips/>
          <Nutrition/>
    </div>
  );
}

export default ProductFeatures;
