import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";

const AnyReactComponent = ({ text }) => <div>{text}</div>;


export class MapContainer extends Component {
        render() {
              const style = {
                position: "absolute",
                zIndex: 1,
                width: "95%",
                height: "500px",
              };
              return (
                <Map
                    google={this.props.google}
                    initialCenter={{
                      lat: 12.8917,
                      lng: 77.5827,
                    }}
                    zoom={15}
                    style={style}
                >
                    <Marker 
                        onClick={this.onMarkerClick}
                        name={"Current location"} 
                    />
                </Map>               
            );
        }
}

export default GoogleApiWrapper({
  apiKey:"AIzaSyDj6ug5V9wQIf5pfvb9TPItpfNXwJg8fL4"
})(MapContainer);