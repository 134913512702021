import React from 'react'

function NutritionItem({image, name, desc, hyplink}) {
    return (
        <div className="nutritionItem" >
            <div style={{backgroundImage: `url(${image})` }}> </div>
            <a>{name}</a>
            <a href={hyplink}> KNOW MORE.. </a>
        </div>
    )
}

export default NutritionItem
