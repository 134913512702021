import React from 'react'
import BannerImage from "../../assets/vaccdetails_bkgnd.png";
import WriteToUs from "../../components/WriteToUs";

function Mmr() {
  return (
    <div style={{fontFamily: "Roboto", backgroundImage: `url(${BannerImage})` }}>      
        <div style={{fontSize:"16sp", textAlign:'left'}}>
          <h1>Measles, Mumps, and Rubella Vaccine (MMR)</h1>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>About Vaccine:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
          <p>
          The measles, mumps, and rubella (MMR) vaccine protects children
          against three potentially serious illnesses. MMR is a three-shot 
          series of vaccines usually given during childhood. A child should 
          receive the first shot when they are between 9-12 weeks, and the
          second when they're between 4-6 months of age. 
          </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Total number of dosages: 3</h1>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Dosage Time:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'left'}}>
          <p>9 Weeks
             <br></br>
             15 Weeks
             <br></br>
             4-6 Months
        </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Importance:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
            <p>
            Measles starts as a fever, cough, runny nose, conjunctivitis
            (pinkeye), and a red, pinpoint rash that starts on the face and
            spreads to the rest of the body. 
            <br></br>
            <br></br>
            The mumps virus usually causes swelling in glands just below the
            ears, giving the appearance of chipmunk cheeks. Before the vaccine,
            mumps was the most common cause of both meningitis (inflammation 
            of the lining of the brain and spinal cord) and acquired deafness.
            <br></br>
            <br></br>
            Rubella is also known as German measles. It can cause a mild rash
            on the face, swelling of glands behind the ears, and in some cases,
            swelling of the small joints and low-grade fever. Most children 
            recover quickly with no lasting effects. But if a pregnant woman gets
            rubella, it can be devastating.
            </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>After Effects:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
            <p>
            Most children who receive the MMR vaccine have no side effects. 
            Some have fever or minor soreness and redness where they got the shot.Other
            possible problems include fever (1 in 5 children), rash (1 in 20)
            and swollen glands (1 in 7).
            </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Tentative Cost:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'left'}}>
          <p>
          Rs 70 - 499
          </p>
        </div>  

        <br></br>
        <br></br>
        <h1 style={{marginLeft: "18sp"}} >Write to us:</h1>

        <WriteToUs/>
    
    </div>  
    )
}

export default Mmr