import React from 'react'
import BannerImage from "../../assets/vaccdetails_bkgnd.png";
import WriteToUs from "../../components/WriteToUs";


function Opv() {
  return (
    <div style={{fontFamily: "Roboto", backgroundImage: `url(${BannerImage})` }}>
        <div style={{fontSize:"16sp", textAlign:'left'}}>
            <h1>Oral Polio Vaccine (OPV)</h1>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
            <h1>About Vaccine:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
            <p>The Oral Polio Vaccine (OPV) is a live but weakened form 
            of the virus that makes the body produce antibodies against
            it without developing a disease. The OPV is administered 
            in the body as oral drops and helps protect both the child 
            and those living around.
            <br></br>
            <br></br>

            OPVs are inexpensive, safe and offer long lasting protection
            against the virus. As the polio vaccine is administered
            orally, it is much easier and does not require specialised 
            health professionals or equipment. The OPV can be given 
            if your baby has fever or diarrhoea, however, do consult
            your doctor.
            </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
            <h1>Total number of dosages: 4</h1>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
            <h1>Dosage Time:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'left'}}>
            <p>At Birth
            <br></br>
            6 Months
            <br></br>
            9 Months
            <br></br>
            4 to 6 years</p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
            <h1>Importance:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
            <p>Polio is an infectious disease caused by a virus that lives
            in the throat and intestinal tract. The polio virus 
            enters through your mouth and ends up in the nervous system. 
            It can rapidly lead to paralysis and sometimes even death. 
            Polio mainly affects children under the age of 5. Older people
            with weakened immune systems are also at risk.   It is a common
            belief that polio affects only the lower socio-economic group. 
            However, this is untrue; all children are equally at risk.

            <br></br>
            <br></br>
            There is no cure for polio. The best way to protect your child
            is by ensuring that they get immunised with the polio vaccine 
            at the right time.
            </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
            <h1>After Effects:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
            <p>Some parents have concerns regarding the side effects of
            polio vaccine. The vaccine is proven to be safe and one of 
            the most effective tools in preventing polio. However, there
            is a rare chance (one in 2.4 million people) that your child 
            may contract polio from the live but diluted virus. If you have
            any concerns regarding polio vaccination, discuss them openly 
            with your doctor.
            </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
            <h1>Tentative Cost:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'left'}}>
            <p>
            Rs 72 - 115
            </p>
        </div>

        <br></br>
        <br></br>
        <h1 style={{marginLeft: "18sp"}} >Write to us:</h1>

        <WriteToUs/>

  </div>
)
}

export default Opv