import React, { useState } from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import '../styles/Navbar.css';
import dropdown from './Dropdown';
import { faBars, faXmark, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NavBarImage from "../assets/navbar_bkgnd.png";
import Logo from "../assets/shishuraksha_logo.png";


function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  return (
    <div>
      <div className='navbar' style={{backgroundImage: `url(${NavBarImage})` }}>
        <Link to='/' className='navbar-logo'>
          <img src={Logo} alt="alternative"/>          
        </Link>
        <div className='menu-icon' onClick={handleClick}>     
             <FontAwesomeIcon icon={click ? faXmark : faBars}/>
        </div>
        
        <ul className={click ? 'nav-menu-active' : 'nav-menu'}>
          <li className='nav-item'>
            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
              Home
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/about'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              About Us
            </Link>
          </li>

          <li className='nav-item'>
            <Link
              to='/productfeatures'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              Product Features
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/contact'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;