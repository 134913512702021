import React from 'react';
import emailjs from "emailjs-com";
import "../styles/Contact.css";
import MapContainer from "../components/MapContainer";
import ContactBkgnd from "../assets/contact_bkgnd.png";
import WriteToUs from "../components/WriteToUs";



function Contact() {

    return (
        <div className="contact" style={{backgroundImage: `url(${ContactBkgnd})` }}>
             <h1 style={{marginLeft: "15px"}}>SeaPebble Technologies Private Ltd.</h1> 
             <div className="leftSide">
                <h1>Contact Us</h1>
                <WriteToUs/>
            </div>

            <div className="rightSide">
                <div className='mapcontainer'>
            
                    <MapContainer/>  

                </div>
                       
            </div>
        </div>
    )
}

export default Contact
