import React from 'react'
import BannerImage from "../../assets/vaccdetails_bkgnd.png";
import WriteToUs from "../../components/WriteToUs";

function Ipv() {
  return (
    <div style={{fontFamily: "Roboto", backgroundImage: `url(${BannerImage})` }}>      
        <div style={{fontSize:"16sp", textAlign:'left'}}>
          <h1>Inactivated poliovirus vaccine (IPV)</h1>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>About Vaccine:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
          <p>The IPV vaccine consists of inactivated (killed) poliovirus
            strains of all three poliovirus types. IPV is given by intramuscular
            or intradermal injection and needs to be administered by a trained
            health worker. IVP produces antibodies in the blood to all three
            types of poliovirus. In the event of infection, these antibodies
            prevent the spread of the virus to the central nervous system
            and protect against paralysis.
          </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Total number of dosages: 3</h1>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Dosage Time:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'left'}}>
          <p>6 Weeks
             <br></br>
             10 Weeks
             <br></br>
             14 Weeks
        </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Importance:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
          <p>Polio is an infectious disease caused by a virus that lives
              in the throat and intestinal tract. The polio virus enters 
              through your mouth and ends up in the nervous system. It can
              rapidly lead to paralysis and sometimes even death. Polio mainly
              affects children under the age of 5. Older people with weakened 
              immune systems are also at risk.   It is a common belief that 
              polio affects only the lower socio-economic group. However, this is
              untrue; all children are equally at risk.
            <br></br>
            <br></br>
              IVP produces antibodies in the blood to all three types of 
              poliovirus. In the event of infection, these antibodies prevent 
              the spread of the virus to the central nervous system and protect 
              against paralysis.
            </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>After Effects:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
          <p>Side effects include fever and redness or soreness at the injection
             site. There is a very small chance of an allergic reaction with 
             any vaccine.
          </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Tentative Cost:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'left'}}>
          <p>
          Rs 71 - 115
          </p>
        </div>  

        <br></br>
        <br></br>
        <h1 style={{marginLeft: "18sp"}} >Write to us:</h1>

        <WriteToUs/>
    
    </div>  )
}

export default Ipv