import React from 'react';
import "../styles/VaccinationSlideShow.css";

const images = [
  {
    picture: require("../assets/vacc_slide1.png")
  },
  {
    picture: require("../assets/vacc_slide2.png")
  },
  {
    picture: require("../assets/vacc_slide3.png")
  },
  {
    picture: require("../assets/vacc_slide4.png")
  },
  {
    picture: require("../assets/vacc_slide5.png")
  },
  {
    picture: require("../assets/vacc_slide6.png")
  }

];

const delay = 4500;


function VaccinationSlideShow() {
    const [index, setIndex] = React.useState(0);
    const timeoutRef = React.useRef(null);
  
    function resetTimeout() {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }
  
    React.useEffect(() => {
      resetTimeout();
      timeoutRef.current = setTimeout(
        () =>
          setIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
          ),
        delay
      );
  
      return () => {
        resetTimeout();
      };
    }, [index]);
  
    return (
      <div className="slideshow">
        <div
          className="slideshowSlider"
          style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
        >
          {images.map((pic, index) => (
            <div
              className="slide"
              key={index}
            >
              <img src={pic.picture}/>
            </div>
          ))}
        </div>
  
        <div className="slideshowDots">
          {images.map((_, idx) => (
            <div
              key={idx}
              className={`slideshowDot${index === idx ? " active" : ""}`}
              onClick={() => {
                setIndex(idx);
              }}
            ></div>
          ))}
        </div>
      </div>
    );
  }
  
  
export default VaccinationSlideShow;
