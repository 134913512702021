import "./App.css";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import About from "./pages/About";
import ProductFeatures from "./pages/ProductFeatures";
//import Contact from "./components/Sendemail";
import Contact from "./pages/Contact";
import Bcg from "./pages/Vaccines/Bcg";
import Opv from "./pages/Vaccines/Opv";
import HepatitisB from "./pages/Vaccines/HepatitisB";
import Tdap from "./pages/Vaccines/Tdap";
import Ipv from "./pages/Vaccines/Ipv";
import Rotavirus from "./pages/Vaccines/Rotavirus";
import Pcv from "./pages/Vaccines/Pcv";
import Hib from "./pages/Vaccines/Hib";
import Mmr from "./pages/Vaccines/Mmr";
import Tcv from "./pages/Vaccines/Tcv";
import HepatitisA from "./pages/Vaccines/HepatitisA";
import Varicella from "./pages/Vaccines/Varicella";
import Btc from "./pages/Vaccines/Btc";
import Hpv from "./pages/Vaccines/Hpv";
import Services from './pages/Services';
import SignUp from './pages/SignUp';
import Marketing from './pages/Marketing';
import Consulting from './pages/Consulting';

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import MapContainer from "./components/MapContainer";



function App() {
  return (
    <div className="App"> 
      <Router>
        < Navbar />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/about" component={About} />
            <Route exact path="/productfeatures" component={ProductFeatures} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/Vaccines/Bcg" component={Bcg} />
            <Route exact path="/Vaccines/Opv" component={Opv} />
            <Route exact path="/Vaccines/HepatitisB" component={HepatitisB} />
            <Route exact path="/Vaccines/Tdap" component={Tdap} />
            <Route exact path="/Vaccines/Ipv" component={Ipv} />
            <Route exact path="/Vaccines/Rotavirus" component={Rotavirus} />
            <Route exact path="/Vaccines/Pcv" component={Pcv} />
            <Route exact path="/Vaccines/Hib" component={Hib} />
            <Route exact path="/Vaccines/Mmr" component={Mmr} />
            <Route exact path="/Vaccines/Tcv" component={Tcv} />
            <Route exact path="/Vaccines/HepatitisA" component={HepatitisA} />
            <Route exact path="/Vaccines/Varicella" component={Varicella} />
            <Route exact path="/Vaccines/Btc" component={Btc} />
            <Route exact path="/Vaccines/Hpv" component={Hpv} />

            <Route exact path="/services" component={Services} />
            <Route exact path="/consulting" component={Consulting} />
            <Route exact path="/marketing" component={Marketing} />

          </Switch>
          <Footer/>
      </Router>
    </div>
  );
}

export default App;
