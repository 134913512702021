import React from 'react'
import Divider from "@material-ui/core/Divider";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";


function VaccinesItem({heading, hyplink}) {
    return (
        <div className="vaccinesItem" >
                <p>{heading}</p>
                <a href={hyplink}> Know more.. </a>     
        </div>
    )
}

export default VaccinesItem
