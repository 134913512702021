import React from "react";
import { Link } from "react-router-dom";
import BannerImage from "../assets/shishuraksha_home.png";
import "../styles/Home.css";

function Home() {
    return(
        <div className="home" style={{backgroundImage: `url(${BannerImage})`}}>
            <div className="headerContainer">           
                <h1> ShishuRaksha </h1>
                <p> Healthy children make healthy future </p>
            </div>
        </div>
    );  
}

export default Home
