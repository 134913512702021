import React from 'react';
import BannerImage from "../../assets/vaccdetails_bkgnd.png";
import WriteToUs from "../../components/WriteToUs";

function Hpv() {
  return (
    <div style={{fontFamily: "Roboto", backgroundImage: `url(${BannerImage})` }}>      
        <div style={{fontSize:"16sp", textAlign:'left'}}>
          <h1>Human papillomavirus Vaccine (HPV) </h1>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>About Vaccine:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
          <p>
          HPV vaccines protect against a very common virus transmitted sexually 
          or by skin-to skin-contact known as human papillomavirus. The body 
          often clears the virus on its own. But if the virus persists, it can
          lead to cervical, anal, and throat cancers and to genital warts. The 
          vaccine is given as an injection to the body. 
          </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Total number of dosages: 1</h1>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Dosage Time:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'left'}}>
          <p>
          10 - 12 Years
          </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Importance:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
            <p>
            Human papillomavirus (HPV) is a virus that can cause cervical 
            cancer as well as genital warts. It can spread through sex and from
            some types of skin-to skin-contact. An HPV infection also can cause 
            these other problems - cancer of the cervix , vagina, vulva, penis, 
            anus , mouth, and throat.
            <br></br>
            <br></br>
            Most people infected with HPV don't know they have it because they
            don't notice any signs or problems. People do not always get genital
            warts, but the virus is still in their system and could cause damage. 
            This means that people with HPV can pass the infection to others without 
            knowing it.
            <br></br>
            <br></br>
            HPV Vaccine is an important step in preventing infection and protecting
            against the spread of HPV.
            </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>After Effects:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
            <p>
            Side effects that people get from the HPV vaccine usually are minor.
            They may include swelling or pain at the injection site, or feeling 
            faint after getting the vaccine.
            </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Tentative Cost:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'left'}}>
          <p>
          Rs. 6000-8400
          </p>
        </div>  

        <br></br>
        <br></br>
        <h1 style={{marginLeft: "18sp"}} >Write to us:</h1>

        <WriteToUs/>
    
    </div>  
    )
}

export default Hpv