
export const VaccinesList = [
  {
    heading: "Bacille Calmette-Guerin (BCG)",
    hyplink: "/Vaccines/Bcg",
  },
  {
    heading: "Oral Polio Vaccine (OPV)",
    hyplink: "/Vaccines/Opv",
  },
  {
    heading: "Hepatitis B Vaccine",
    hyplink: "/Vaccines/HepatitisB",
  },
  {
    heading: "Tetanus and Diptheria Toxoids and a Cellular Pertussis\
    Vaccine (TdaP)",
    hyplink: "/Vaccines/Tdap",
  },
  {
    heading: "Inactivated poliovirus vaccine (IPV)",
    hyplink: "/Vaccines/Ipv",
  },
  {
    heading: "Rotavirus",
    hyplink: "/Vaccines/Rotavirus",
  },
  {
    heading: "Pneumococcal Vaccine (PCV)",
    hyplink: "/Vaccines/Pcv",
  },
  {
    heading: "Haemophilus influenzae Vaccine (Hib)",
    hyplink: "/Vaccines/Hib",
  },
  {
    heading: "Measles, Mumps, and Rubella Vaccine (MMR)",
    hyplink: "/Vaccines/Mmr",
  },
  {
    heading: "Typhoid Conjugate Vaccine (TCV)",
    hyplink: "/Vaccines/Tcv",
  },
  {
    heading: "Hepatitis A Vaccine (HepA)",
    hyplink: "/Vaccines/HepatitisA",
  },
  {
    heading: "Chickenpox (Varicella) Vaccine",
    hyplink: "/Vaccines/Varicella",
  },
  {
    heading: "Booster Typhoid Conjugate Vaccine (BTC)",
    hyplink: "/Vaccines/Btc",
  },
  {
    heading: "Human papillomavirus Vaccine (HPV)",
    hyplink: "/Vaccines/Hpv",
  },
];
