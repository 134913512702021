import Pepperoni from "../assets/pepperoni.jpg";
import SleepingMotherBaby from "../assets/sleeping_mother_baby.jpg";
import BalancedDiet from "../assets/tips_balanced_diet_image.png";
import TalkToChild from "../assets/tips_talk_to_child_image1.png";
import Vegan from "../assets/vegan.jpg";
import Pineapple from "../assets/pineapple.jpg";
import Expensive from "../assets/expensive.jpg";


export const KidsCareTipsList = [
  {
    heading: "General Tips",
    image: SleepingMotherBaby,
    desc: "ShihuRaksha provides useful general tips on your kids health care. It provides essential tips on taking care of your baby'sleep, teaching them to make decisions, handling a crying baby, building confidence and self esteem and not over prpteting your kid.",
    hyplink: "/faq",
  },
  {
    heading: "Health Tips",
    image: BalancedDiet,
    desc: "ShihuRaksha provides useful health tips on your kids up bringing. It provides essential tips on not pressurizing your kid to eat more, invoilving your kid in appointment with doctor, paying attanetion to chnge in eating habits, balanced diet, keeping your chid engaged, getting enough sleep and maintaining hygiene.",
    hyplink: "/faq",
  },
  {
    heading: "Behavioural Tips",
    image: TalkToChild,
    desc: "ShihuRaksha provides useful behavioural tips on your kids up bringing. It provides essential tips on your baby's potty habits, your kid's tantrums, giving them enough attention, correcting misbehaviour, talking closely with them, setting good examples and showing them respect .",
    hyplink: "/faq",
  },
];
