import React from 'react';
import BannerImage from "../../assets/vaccdetails_bkgnd.png";
import WriteToUs from "../../components/WriteToUs";


function Varicella() {
  return (
    <div style={{fontFamily: "Roboto", backgroundImage: `url(${BannerImage})` }}>      
        <div style={{fontSize:"16sp", textAlign:'left'}}>
          <h1>Chickenpox (Varicella) Vaccine</h1>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>About Vaccine:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
          <p>
          The Varicella vaccine is so named because chickenpox is caused by
          the varicella-zoster virus. The vaccine is made from a live but
          weakened, or attenuated, virus. 
          </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Total number of dosages: 2</h1>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Dosage Time:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'left'}}>
          <p>
          15 Months
          <br></br>
          4-6 Years
          </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Importance:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
            <p>
            Most cases of chickenpox are relatively mild and run their course in
            five to 10 days. But it can be very serious, even life-threatening, in
            a small percentage of people. The risk of serious, life-threatening
            complications is greatest among infants, elderly adults, and people
            with weakened immune systems.
            <br></br>
            <br></br>
            The illness is highly contagious and without the vaccine, it can
            be spread by direct contact or through the air by sneezing or coughing.
            Also, someone can get it by coming in contact with fluid from chickenpox
            blisters. For that reason, children with chickenpox need to be kept out
            of school or day care for about a week or more until all blisters have
            dried and crusted over.
            </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>After Effects:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
            <p>
            Side effects associated with the varicella vaccine are generally mild
            . The most common are pain, redness, or swelling at the injection site.
            </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Tentative Cost:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'left'}}>
          <p>
          Rs. 1345
          </p>
        </div>  

        <br></br>
        <br></br>
        <h1 style={{marginLeft: "18sp"}} >Write to us:</h1>

        <WriteToUs/>
    
    </div>  
    )
}

export default Varicella