import React from 'react';
import BannerImage from "../../assets/vaccdetails_bkgnd.png";
import WriteToUs from "../../components/WriteToUs";


function HepatitisA() {
  return (
    <div style={{fontFamily: "Roboto", backgroundImage: `url(${BannerImage})` }}>      
        <div style={{fontSize:"16sp", textAlign:'left'}}>
          <h1>Hepatitis A Vaccine (HepA)</h1>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>About Vaccine:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
          <p>
          The HepA vaccine is made from inactive hepatitis A virus (HAV)
          virus and is given as shots to protects children from getting the
          infection. 
          </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Total number of dosages: 2</h1>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Dosage Time:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'left'}}>
          <p>
             1 Years
             <br></br>
             <br></br>
             18 Months
          </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Importance:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
            <p>
            Hepatitis A is an infection of the liver caused by the hepatitis A
            virus (HAV) virus which can be serious or even life-threatening. People
            with hepatitis A can have no symptoms or they can feel very sick with
            fever, nausea, vomiting, and jaundice .
            <br></br>
            <br></br>
            The HepA vaccine not only protects the kids from getting the infection
            caused by the Hepatitis A virus (HAV). It also can help prevent outbreaks.
            An outbreak is when a disease happens in greater numbers than expected
            in a particular area.
            </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>After Effects:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
            <p>
            Side effects usually are mild, and can include a mild fever and soreness
            or redness at the injection site. Allergic reactions to the vaccine are
            rare.
            </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Tentative Cost:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'left'}}>
          <p>
          Rs. 915 - 1215
          </p>
        </div>  

        <br></br>
        <br></br>
        <h1 style={{marginLeft: "18sp"}} >Write to us:</h1>

        <WriteToUs/>
    
    </div>  

    )
}

export default HepatitisA