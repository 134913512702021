import React from 'react';
//import VaccinationSlideShow from "../components/VaccinationSlideShow";
import "../styles/Vaccination.css";
//import VaccinationBkgnd from "../assets/vaccination_bkgnd.png";


function Vaccination() {
  return (
    <div className="vaccination" id="vaccination">
        <h1 className="vaccinationTitle">Vaccination</h1>
        <div className="vaccinationItem">
            <p>
                Vaccinations for your child that includes setting reminders, updating data on age wise vaccinations completed, details on the Vaccines, Hospitals/Clinics available, creating and viewing health records.
            </p>
        </div>
    </div>
  )
}

export default Vaccination;
