import React from 'react'
import { NutritionList } from "../helpers/NutritionList";
import NutritionItem from "../components/NutritionItem";
import "../styles/Nutrition.css";
import NutritionBkgnd from "../assets/nutrition_bkgnd.png";

function Nutrition() {
    return (
        <div className="nutrition" id="nutrition" style={{backgroundImage: `url(${NutritionBkgnd})` }}>
            <h1 className="nutritionTitle">Nutrition</h1>
            <p className="nutritionDescription">
                ShishuRaksha provides useful nutritional tips for the child from birth 
                till 18 Years at important stages of the child's growth. It also
                details the essential nutrients that should form a part of the child's
                diet for a healthy growth.
            </p>
            <div className="nutritionList">{NutritionList.map((nutritionItem, key) => {
                return <div>
                    <NutritionItem 
                    key={key}
                        image={nutritionItem.image}
                        name={nutritionItem.name}
                        hyplink={nutritionItem.hyplink}   
                    />
                </div>                
            })}
            </div>
        </div>
    )
}

export default Nutrition
