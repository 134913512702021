import React from 'react'
import BannerImage from "../../assets/vaccdetails_bkgnd.png";
import WriteToUs from "../../components/WriteToUs";

function Hib() {
  return (
    <div style={{fontFamily: "Roboto", backgroundImage: `url(${BannerImage})` }}>      
        <div style={{fontSize:"16sp", textAlign:'left'}}>
          <h1>Haemophilus influenzae Vaccine (Hib)</h1>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>About Vaccine:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
          <p>
              Hib vaccine, provides safe and effective protection against Hib 
              disease caused by Haemophilus influenzae bacterium which is an 
              invasive bacterial infection that at one time was the most common
              cause of bacterial meningitis in young children; invasive means 
              that germs spread to parts of the body that are normally germ-free. 
          </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Total number of dosages: 3</h1>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Dosage Time:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'left'}}>
          <p>6 Weeks
             <br></br>
             14 Weeks
             <br></br>
             6 Months
        </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Importance:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
            <p>
              In addition to bacterial meningitis, the Hib bacterium can
              also cause pneumonia; epiglottitis, which is an infection in
              the throat that can cause breathing difficulties; blood infection;
              bone infection; and joint infection leading to arthritis. The 
              Hib germ is spread in droplets that come from sneezing or coughing.
            <br></br>
            <br></br>
              Hib Vaccine protects children from the diseases caused by the Haemophilus
              influenzae bacterium.
            </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>After Effects:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
          <p>
            The most common side effects include soreness, swelling, or
            redness at the site of the injection. There are no serious side
            effects, and severe allergic reactions are rare.
          </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Tentative Cost:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'left'}}>
          <p>
          Rs 11 - 750
          </p>
        </div>  

        <br></br>
        <br></br>
        <h1 style={{marginLeft: "18sp"}} >Write to us:</h1>

        <WriteToUs/>
    
    </div>  
    )
}

export default Hib