import React from 'react'
import BannerImage from "../../assets/vaccdetails_bkgnd.png";
import WriteToUs from "../../components/WriteToUs";


function HepatitisB() {
  return (
    <div style={{fontFamily: "Roboto", backgroundImage: `url(${BannerImage})` }}>      
        <div style={{fontSize:"16sp", textAlign:'left'}}>
          <h1>Hepatitis B vaccine</h1>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>About Vaccine:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
          <p>The Hepatitis B vaccine usually creates long-term immunity.
            Most infants who get the Hepatitis B vaccines are protected 
            from hepatitis B infection beyond childhood, into their adult
            years.
            <br></br>
            <br></br>
            Eliminating the risk of infection also decreases risk for cirrhosis
            of the liver, chronic liver disease, and liver cancer.Hepatitis B 
            vaccine is safe and effective. You cannot get hepatitis B from the
            vaccine.
          </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Total number of dosages: 3</h1>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Dosage Time:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'left'}}>
          <p>At Birth
            <br></br>
            6 Weeks
            <br></br>
            6 Months
        </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Importance:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
          <p>Hepatitis B is a serious liver disease caused by the hepatitis
            B virus (HBV). The virus can affect people of all ages. Once 
            infected, some people carry the virus their whole lives. This is
            called “chronic” infection and it can lead to liver cirrhosis, 
            liver cancer, and death.
            <br></br>
            <br></br>
            The virus is found in the blood and body fluids of infected people.
            It is most often spread among adults through sexual contact, or
            from an HBV-infected mother to her newborn during birth. HBV
            can also be spread through normal household contact with HBV-infected
            people.
            </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>After Effects:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'justify'}}>
          <p>The most common side effect of the vaccine is soreness at the
            injection site. As with any medicine, there are very small risks
            that serious problems could occur after getting the vaccine. However,
            the potential risks associated with hepatitis B disease are much 
            reater than the potential risks associated with the hepatitis B 
            vaccine.
          </p>
        </div>
        <div style={{fontSize:"14sp", textAlign:'left'}}>
          <h1>Tentative Cost:</h1>
        </div>
        <div style={{fontSize:"16sp", textAlign:'left'}}>
          <p>
          Rs 100 - 249
          </p>
        </div>  

        <br></br>
        <br></br>
        <h1 style={{marginLeft: "18sp"}} >Write to us:</h1>

        <WriteToUs/>
    
    </div>
  )
}

export default HepatitisB